import React, { useEffect, useRef, useState, useMemo } from "react";
import "../App.css";

const Cursor = () => {
  const coords = useRef({ x: 0, y: 0 });
  const circlesRef = useRef([]);
  const [initialized, setInitialized] = useState(false);

  const colors = useMemo(
    () => [
      "#ffb56b",
      "#fdaf69",
      "#f89d63",
      "#f59761",
      "#ef865e",
      "#ec805d",
      "#e36e5c",
      "#df685c",
      "#d5585c",
      "#d1525c",
      "#c5415d",
      "#c03b5d",
      "#b22c5e",
      "#ac265e",
      "#9c155f",
      "#950f5f",
      "#830060",
      "#7c0060",
      "#680060",
      "#60005f",
      "#48005f",
      "#3d005e",
    ],
    []
  );

  useEffect(() => {
    const handleMouseMove = (e) => {
      coords.current.x = e.clientX;
      coords.current.y = e.clientY;
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const circles = circlesRef.current;
    if (!initialized && circles.length > 0) {
      circles.forEach((circle, index) => {
        if (circle) {
          // Ensure the circle is not null
          circle.x = 0;
          circle.y = 0;
          circle.style.backgroundColor = colors[index % colors.length];
        }
      });
      setInitialized(true);
    }

    const animateCircles = () => {
      let x = coords.current.x;
      let y = coords.current.y;

      circles.forEach((circle, index) => {
        if (circle) {
          // Ensure the circle is not null
          circle.style.left = `${x - 12}px`;
          circle.style.top = `${y - 12}px`;

          circle.style.transform = `scale(${
            (circles.length - index) / circles.length
          })`;

          circle.x = x;
          circle.y = y;

          const nextCircle = circles[index + 1] || circles[0];
          x += (nextCircle.x - x) * 0.3;
          y += (nextCircle.y - y) * 0.3;
        }
      });

      requestAnimationFrame(animateCircles);
    };

    if (initialized) {
      requestAnimationFrame(animateCircles);
    }
  }, [initialized, colors]);

  return (
    <>
      {[...Array(22)].map((_, index) => (
        <div
          key={index}
          className="mouseCircle"
          ref={(el) => (circlesRef.current[index] = el)}
        ></div>
      ))}
    </>
  );
};

export default Cursor;
