import React, { useEffect, useState } from "react";
import style from "../css/speaker.module.css";
import SpeakerCard from "./SpeakerCard";
import Navbar from "./Navbar";

function Speaker(props) {
  const [flex, setFlex] = useState("row");
  const [vid, setvid] = useState(0);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 992) {
        setFlex("column");
      } else {
        setFlex("row");
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {window.location.pathname === "/speakers" ? <Navbar /> : null}
      <div className={style.speakers + " speakers"}>
        <div className={style.heading} id="speakers">
          <span className={style.title}></span>
        </div>

        <div className={style.content}>
          {props.speakers.map((val, ind) => {
            const dir = ind % 2 === 0 ? "row" : "row-reverse";
            const flex = window.innerWidth <= 992 ? "column" : dir;
            return <SpeakerCard ind={ind} flex={flex} val={val} />;
          })}
        </div>
      </div>
    </>
  );
}

export default Speaker;
