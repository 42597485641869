import React, { useState } from "react";
import styles from "../css/pastspeakercard.module.css";

function PastSpeakers(props) {

    if(props.year == 2023){

    return (<>
        <div className={styles.layout}>
            
     
        {props.pastspeaker.map((val, ind) => {

            return(
            <div className={styles.card}>

             <div className={styles.profilepic2023}>

                <img src={val.img} alt="" className={styles.image2023}/>
             </div>

             <div className={styles.nameplate2023}>

                <div className={styles.name2023}>

                    {val.name}

                </div>

                <div className={styles.data2023}>

                     {val.data}

                </div>
                </div>
                    
            </div>          
            )
          })}

            








        </div>
    </>
    )

}

if(props.year == 2022){

    return (<>
        <div className={styles.layout}>
            
     
        {props.pastspeaker.map((val, ind) => {

            return(
            <div className={styles.card2}>

             <div className={styles.profilepic2022}>

                <img src={val.img} alt="" className={styles.image2022}/>
             </div>

             <div className={styles.nameplate2022}>

                <div className={styles.name2022}>

                    {val.name}

                </div>

                <div className={styles.data2022}>

                     {val.data}

                </div>
                </div>
                    
            </div>          
            )
          })}

            








        </div>
    </>
    )
    

}





};

export default PastSpeakers;