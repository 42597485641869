import React, { useEffect, useRef, useState } from "react";
import "../css/eventCoverageandFootfall.css";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import styles from "./../css/EventCoverageAndFootdfall.module.css";
import { motion } from "framer-motion";

function CountDiv({ number, text }) {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger only once when it comes into view
    threshold: 0.1, // Trigger when 10% of the component is in view
  });

  return (
    <div className=" flex flex-col  h-[300px] pt-7">
      <div
        ref={ref}
        className={`bg-black text-white aspect-square w-48 text-2xl font-semibold grid justify-center content-center rounded-full ${styles.counterDiv}`}
      >
        {inView && <CountUp end={number} duration={2} suffix="+" />}
      </div>
      <div className="text-white text-2xl text-center text-wrap w-48 mt-4">
        {text}
      </div>
    </div>
  );
}

export default function EventCoverageAndFootfall() {
  const containerRef = useRef(null);
  const [cnt, setcnt] = useState(0);
  console.log(cnt);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.5,
  });
  useEffect(() => {
    if (inView) {
      if (cnt === 0) {
        setcnt(1);
      }
    }
  }, [inView]);
  const [animate, setanimate] = useState("box");

  const [animated, setanimated] = useState("box");
  return (
    <div className="grid justify-center">
      <motion.div
        className={`w-[95vw]  ${styles.eventDiv}`}
        initial={{ opacity: 0.2, transform: `scale(0.1)` }}
        whileInView={{ opacity: 1, transform: `scale(1)` }}
        transition={{
          duration: 1,
          ease: "linear",
          type: "spring",
        }}
      >
        <div className="flex justify-around md:flex-row flex-col items-center flex-wrap">
          <CountDiv number={70000} text={"Fest Reach"} />
          <CountDiv number={3000} text={"Attendees"} />
          <CountDiv number={5000} text={"Registrations and Competitions"} />
          <CountDiv number={5000} text={"Campus Strength"} />
        </div>
      </motion.div>
    </div>
  );
}
