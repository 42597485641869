export const eSummitDescription = `
Through years of relentless effort, unwavering determination, and commitment, the Indian Institute of Technology, Indore, has risen to prominence among the new generation of IITs. Our achievements span academics, sports, and technical and cultural events, with our students consistently demonstrating their capabilities nationwide. This impressive record of achievements underscores our ambition to excel further and reach even greater heights.<br /><br />

The Annual Entrepreneurship Fest, E-Summit, organized by E-Cell IIT Indore, distinguishes itself as the most anticipated event in the entrepreneurial ecosystem. E-Summit not only boasts motivational speakers such as Aman Dhattarwal, Tanu Jain, Shradha Khapra, Sandeep Jain and their path breaking speeches but also channelises youthful entrepreneurial zeal into dynamic ventures through engaging workshops, events and competitions.<br /><br />

We’re gearing up for the Fourth Edition of E Summit, which promises to be bigger, brighter, and better than ever before. With preparations underway, we are all set to explore the power of relentless ambition to fuel the fire within and forge empires out of obstacles!
`;

export const caWhyApply = [
  `Marketing & Sales Internship Certificate, signed by President, Gymkhana IIT Indore & Overall Coordinator, Esummit IIT Indore, to all CAs, at the end of successful completion of tenure.`,
  `Certificate of Appreciation to Top 100 Performing CAs.`,
  `Letter of Recommendation from Esummit IIT Indore to Top 50 CAs.`,
  `For Best CA - Certificate of Excellence + Photograph opportunity with Esteemed Speakers + VIP passes to Talk Show + Merchandise ( Zipper + Diary + Goodies & Hampers) + Access to free courses and workshops with proper certification.`,
  `Top CAs: Various rewards including VIP Esummit passes, Merchandise, Free courses/workshops, and Discounted fest passes, based on ranking.`,
  `Stand a chance to collaborate with Esummit IIT Indore to organize workshops and tech and entrepreneurship competitions at your institution`,
  `Extensive networking with students of IIT Indore and students from various colleges.`,
];

export const caResponsibilities = [
  {
    title: "Be the Face of Esummit at Your Institute",
    description:
      "Act as a representative of Esummit '24 at your institution, reflecting its spirit and values. Act as a bridge between E-Summit and your campus, building and maintaining a strong connection, acting as the main point of contact.",
    icon: "/data/caicons/1.png",
  },
  {
    title: "Lead and Execute events",
    description:
      "Organize and coordinate campus events related to Esummit '24, ensuring smooth execution and engagement from participants within your institute.",
    icon: "/data/caicons/1.png",
  },
  {
    title: "Promote Esummit '24",
    description:
      "Envoke awareness about Esummit '24, through various channels, both offline and online. Run marketing campaigns to boost attendance and interest among students.",
    icon: "/data/caicons/3.png",
  },
  {
    title: "Engage with Students",
    description:
      "Influence fellow students to encourage their participation and address any questions or concerns they may have about Esummit '24. Foster a vibrant Entrepreneur community by building connections and networks among students.",
    icon: "/data/caicons/4.png",
  },
  {
    title: "Oversee Social Media Engagement",
    description:
      "Manage and boost Esummit'24's social media presence across platforms such as Facebook, Instagram, and Twitter. Post updates, event information, and compelling content to draw in a broader audience.",
    icon: "/data/caicons/5.png",
  },
  {
    title: "Bring Creativity to the Forefront",
    description:
      "CAs can also infuse their creativity through graphic design and video editing to develop promotional materials, campaign ideas, and other content to enhance the event's visibility and appeal.",
    icon: "/data/caicons/6.png",
  },
];

export const faqs = [
  {
    question: "What is the Eligibility Criteria to become a Campus Ambassador?",
    answer:
      "Any candidate enrolled in a university or college, with good communication skills and an enthusiasm for entrepreneurship events can apply. An active social media presence of the candidate would be preferred.",
  },
  {
    question: "What skills will I learn as a Campus Ambassador of E-Summit?",
    answer:
      "The Campus Ambassador program offers students a unique opportunity to gain exposure and witness the events of E-Summit, IIT Indore. By taking on responsibilities, ambassadors will hone their communication, management, and time management skills. They'll also have the chance to build a strong network with fellow enthusiasts.",
  },
  {
    question: "How will be the interview/selection process conducted?",
    answer:
      "The interview/selection process details will be provided separately.",
  },
  {
    question: "What benefits do I receive as a Campus Ambassador?",
    answer:
      "The Campus Ambassadors will be awarded with Marketing and Sales Internship Certificate at the end of the program. Letter of Recommendation will be provided to the top Campus Ambassadors. You also get access to premium courses along with their certificates. Also, get a chance to wear IIT Indore merchandise and goodies!",
  },
  {
    question: "How long does the program last?",
    answer: "The duration of the program will be provided separately.",
  },
  {
    question:
      "I am not a resident of Indore, can I still join the Campus Ambassador program?",
    answer:
      "Sure, the program is open for university/college students across India. The interaction and meetings will be held online.",
  },
  {
    question:
      "What past experiences must one have to become a Campus Ambassador?",
    answer:
      "Previous experience isn't a prerequisite for becoming a Campus Ambassador. We value eagerness to learn management skills, enthusiasm, and a strong desire to enhance your soft skills.",
  },
  {
    question: "What kind of work am I required to do?",
    answer:
      "The program provides a variety of tasks and learning opportunities. This includes publicizing E-Summit's competitions and talk shows, promoting the initiatives of E-Cell IIT Indore in your college through various methods, and studying and knowing more about entrepreneurship and industry networking.",
  },
];
