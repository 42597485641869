const galleryImages = [
   
    '/gallery/img1.JPG',
    '/gallery/img3.JPG',
    
    '/gallery/img2.JPG',
    '/gallery/img4.JPG',
    '/gallery/img5.JPG',
    '/gallery/img6.JPG',
    // '/gallery/IMG_0463.JPG',
    // '/gallery/IMG_0409.JPG',
    
    // '/gallery/p3.jpg',
    // '/gallery/p1.jpg',
    
    // '/gallery/IMG_0238.JPG',
    // '/gallery/p4.jpg',
    // '/gallery/IMG_0355.JPG',
  
    
    // '/gallery/p2.jpg',
    // '/gallery/p5.jpg',
    
    
    
 
  ];
  
  export default galleryImages;
  