// import React from 'react'
// import styles from "../css/registerPopup.module.css"
// import { Link } from 'react-scroll';

// const RegisterPopup = () => {
//     return (
//         <div className={styles.button}>
//             <h3 className={styles.glow}>
//                 <a href='https://www.townscript.com/e/esummit-iit-indore-420401'>REGISTER!!</a>
//             </h3>
//         </div>
//     )
// }

// export default RegisterPopup
import React from 'react'
import styles from "../css/registerPopup.module.css"
import Winners from './Winners';
import { Link } from 'react-scroll';

const RegisterPopup = () => {
    return (
        <div className={styles.button}>
            <h3 className={styles.glow}>
            <Link to={Winners} smooth={true} duration={500}>
                    WINNERS
                </Link>
            </h3>
        </div>
    )
}

export default RegisterPopup
