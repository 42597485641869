import React from "react";
import { FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { motion } from "framer-motion";
import styles from "../css/Footer.module.css"; // Import the CSS for Footer
import Navbar from './Navbar';

const moveUpVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

export default function Footer(props) {
  return (
    <>
      {window.location.pathname === '/footer' ? <Navbar /> : null}
      <section className="px-0 footer z-50 relative" id="bottom">
        <footer className={`bg-black py-8 pl-0 sm:px-8 lg:px-24 xl:px-20 w-screen`}>
          <div className="w-screen">
            <div className="px-9 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-0 md:gap-20 items-start">
              <motion.div
                className="flex flex-col space-y-5"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false, amount: 0.5 }}
                variants={moveUpVariants}
              >
                <div className="flex space-x-5 w-92">
                  <div className="w-[50%] space-x-2 flex">
                    <img src="/logo_ecell.svg" alt="Logo" className="w-12 h-[auto]" />
                    <div className="flex flex-col space-y-1">
                      <span className="text-[#0060A1] font-bold text-sm">
                        Ecell
                      </span>
                      <span className="text-gray-200 font-bold text-sm">
                        IIT Indore
                      </span>
                    </div>
                  </div>
                  <div className="w-[50%]">
                    <img src="/e-summit_logo.jpg" alt="Logo" className="h-[auto]" />
                  </div>
                </div>
                <div className="text-gray-400 text-lg pt-9 pb-6">
                  E-SUMMIT 2024 welcomes all the enthusiasts who believe that it's
                  all about making the right choices and taking the plunge.
                  <br />
                  LET'S INNOVATE, LET'S TRANSFORM, LET'S break the monotony.
                </div>
                <div className="flex space-x-10 mx-auto">
    <button className={`${styles.emailbutton} px-3 py-3`}>
      <a href="mailto:esummit@iiti.ac.in" style={{ textDecoration: "none", color: "white" }}>
        Email Us
      </a>
    </button>
    <button className={`${styles.emailbutton} px-3 py-3`}>
      <a href="https://forms.gle/kmMw3E57YTPpFGqg8" style={{ textDecoration: "none", color: "white" }}>
        Merch
      </a>
    </button>
  </div>
              </motion.div>
              <motion.div
                className="flex flex-col space-y-4 py-4 lg:pl-16"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false, amount: 0.5 }}
                variants={moveUpVariants}
              >
                <div className="text-gray-200 text-lg font-bold">Contact For Accomodation</div>
                <div className="text-gray-200 text-lg font-bold">Mahi Shah</div>
                <div className="text-gray-400">+91 94092 18121</div>
                <div className="text-gray-200 text-lg font-bold">Yash Singh</div>
                <div className="text-gray-400">+91 95609 67016</div>
                <div className="text-gray-200 text-lg font-bold">Email :</div>
                <div className="text-gray-400">esummit@iiti.ac.in</div>
              </motion.div>
              <motion.div
                className="flex flex-col space-y-3 py-4"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false, amount: 0.5 }}
                variants={moveUpVariants}
              >
                <div className="text-gray-200 text-lg font-bold">Phone :</div>
                <div className="text-gray-400">Rohan Maji : +91 9474867609</div>
                <div className="text-gray-400">Anubhuti Sharma : +91 6350365964</div>
                <div className="text-gray-400">Agrim Jain : +91 9811085456</div>
                <div className="text-white font-bold text-lg mt-12 py-2 md:mt-4">
                  Follow us:
                </div>
                <div className="flex space-x-4">
                  <a
                    href={props.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-10 h-10 rounded-full bg-[#d9d9d9] flex items-center justify-center">
                      <FaLinkedin className="text-black text-xl" />
                    </div>
                  </a>
                  <a
                    href={props.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-10 h-10 rounded-full bg-[#d9d9d9] flex items-center justify-center">
                      <FaTwitter className="text-black text-xl" />
                    </div>
                  </a>
                  <a
                    href={props.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-10 h-10 rounded-full bg-[#d9d9d9] flex items-center justify-center">
                      <FaInstagram className="text-black text-xl" />
                    </div>
                  </a>
                </div>
              </motion.div>
            </div>
            <div className={`mt-8 text-center text-gray-400`}>
              2024  {props.copyright}
            </div>
          </div>
        </footer>
      </section>
    </>
  );
}
