import React from 'react';
import eventstyle from "../css/events.module.css";
import img1 from "../images/events/1.png";
import img2 from "../images/events/2.png";
import img3 from "../images/events/3.png";
import img4 from "../images/events/4.png";
import img5 from '../images/events/5.png';
import img6 from '../images/events/6.png';
import img7 from '../images/events/7.png';
import fireVideo from "../images/events/video2.mp4";
import Navbar from './Navbar';
export default function Events() {
    const events = [
        {//pitchx
            image: img1,
            description: "E-Summit'24 is happy to announce our event PITCH-X where various startup founders and budding entrepreneurs can come up to present their ideas and startups and they will be judged by an expert panel. So get ready to witness the heart-throbbing environment in PITCH-X",
            gradient: 'linear-gradient(45deg, #3503ad, #f7308c)',
            registerLink: "https://forms.gle/vQ3GTV3HwnzWtcc98",// updated
        },
        {
            image: img2,
            description: 'E-Cell IIT Indore’s flagship event, E-Summit 2024, presents Trading Vault: The Virtual Trading Competition. If you are a Finance Enthusiast and want to try your hands in the world of stock trading, then this is your chance. The competition will be held on our partner platform.',
            gradient: 'linear-gradient(45deg, #ccff00, #09afff)',
            registerLink: "https://community.stockgro.club/form/b80aec52-d33d-462b-97d5-efe08f1a187d",
        },
        {
            image: img3,
            description: 'This is a case-based challenge wherein the participating teams are required to assess the nuances of the company and provide their recommendations based on an in-depth study of the market being operated in, identifying the opportunities and scale of impact they can create',
            gradient: 'linear-gradient(45deg, #e91e63, #ffeb3b)',
            registerLink: "https://unstop.com/competitions/case-chronicles-case-study-challenge-e-summit-2024-iit-indore-iit-indore-1089338?lb=e80isJa&utm_medium=Share&utm_source=WhatsApp",
        },
        {
            image: img4,
            description: "The time has come to showcase your creativity and marketing skills! E-Summit 24 has come up with the 'Product Design Competition', so set your strategy, form new ideas, and convert them into tangible inventions and products. ",
            gradient: 'linear-gradient(45deg, #3503ad, #f7308c)',
            registerLink: "https://unstop.com/competitions/design-odyssey-e-summit-2024-iit-indore-iit-indore-1071551?lb=e80isJa&utm_medium=Share&utm_source=WhatsApp",
        },
        {
            image: img5,
            description: 'Total 8 teams will pass on to this round and will participate in the Offline IPL Auction which will be held at IIT Indore.',
            gradient: 'linear-gradient(45deg, #2196f3, #ffeb3b)',
            registerLink: "https://unstop.com/o/5CuLstQ?lb=e80isJa&utm_medium=Share&utm_source=WhatsApp",
        },
        {
            image: img6,
            description: "E-Cell, IIT Indore's flagship annual event E-Summit 2024, in association with The Quiz Club, IIT Indore presents Entrivia, a business quiz competition that gives you many questions about entrepreneurship, finance, marketing, fundraising, and more. So do participate and show what you are made of!",
            gradient: 'linear-gradient(45deg, #009688, #ff5722)',
            registerLink: 'https://unstop.com/quiz/entrivia-the-business-quiz-e-summit-2024-iit-indore-iit-indore-1100317?lb=e80isJa&utm_medium=Share&utm_source=WhatsApp',
        },
        {
            image: img7,
            description: "E-Cell IIT Indore's flagship annual event, E-Summit 2024, presents Product Breakdown, a product management challenge. This event provides young entrepreneurs with the opportunity to step into the shoes of product managers, making tough decisions and developing out-of-the-box solutions. ",
            gradient: 'linear-gradient(45deg, #f44336, #ffc107)',
            registerLink: 'https://unstop.com/competitions/case-chronicles-case-study-challenge-e-summit-2024-iit-indore-iit-indore-1087502?lb=e80isJa&utm_medium=Share&utm_source=WhatsApp',
        },
       
        
    ];

    const handleClick = (url) => {
        window.open(url, '_blank');
    };

    return (
        <>
        { window.location.pathname === '/events' ? <Navbar/> : null}
        <div className={eventstyle.events} >
       
            <h1 className={`${eventstyle.eventsheading} text-center pb-2 px-2 w-full visible wow animate__animated animate__fadeInUp`} id="events"> </h1>
            {events.map((event, index) => (
                <div className={`${eventstyle.cont} wow animate__animated animate__fadeInUp`} key={index}>
                    <div className={eventstyle.card} style={{ backgroundImage: event.gradient }}>
                        <div className={eventstyle.cardframe}>
                            <div className={eventstyle.cardpicture}>
                                <img src={event.image} alt={event.title} width="100" />
                            </div>
                            <h2 className={eventstyle.cardtitle}>{event.title}</h2>
                        </div>
                        <div className={eventstyle.cardoverlay}></div>
                        <div className={eventstyle.cardcontent}>
                            <video className={eventstyle.cardvideo} src={fireVideo} autoPlay loop muted />
                            <div className={eventstyle.cardtext}>
                                <h2 className={eventstyle.descriptiontitle}>{event.title}</h2>
                                <p className={eventstyle.descriptiontext}>{event.description}</p>
                                <button onClick={() => { handleClick(event.registerLink) }} className={eventstyle.registerbutton}>Register</button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        </>
    );
}
