import React, { useState, useEffect, useRef } from "react";
import "../css/Navbar.css";
import { Link } from "react-router-dom";
import { Link as Link2 } from "react-scroll";
import {HashLink} from "react-router-hash-link";

export default function Navbar() {
  const target = useRef();
  const [vis, setvis] = useState(false);
  const [stickyblow, setStickyblow] = useState(false);
  const [resp, setresp] = useState(false);

  const checked = () => {
    target.current.checked = false;
  };

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
      if (window.scrollY > window.innerHeight) {
        setStickyblow(true);
      } else {
        setStickyblow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/") {
      console.log("Current viewport height:", window.innerHeight);
    }
  }, [scrollTop]);


  return (
    <>
      <div
        className={`homenavbar ${
          scrollTop || vis ? "stickyblow" : ""
        }`}
      >
        <div className="nav_img_home">
          <img src="./esummitt.png" alt="Logo" />
        </div>

      <div className={vis ? "nav_list" : "nav_list list"}>
        <li className='li_item'><Link onClick={()=>{setvis(false) ; checked()}} activeClass='active' className='nav_item' to="/" spy={true} smooth={true} duration={500} >Home</Link></li>
        <li className='li_item'><HashLink onClick={()=>{setvis(false) ; checked()}} activeClass='active' className='nav_item' to="#aboutus" spy={true} smooth={true} duration={500} >About Us</HashLink></li>
        <li className='li_item'><HashLink onClick={()=>{setvis(false) ; checked()}} activeClass='active' className='nav_item' to="#whyca" spy={true} smooth={true} duration={800} >Why CA?</HashLink></li>
        <li className='li_item'><HashLink onClick={()=>{setvis(false) ; checked()}} activeClass='active' className='nav_item' to="#incentives" spy={true} smooth={true} duration={800} >Incentives</HashLink></li>
        <li className='li_item'><HashLink onClick={()=>{setvis(false) ; checked()}} activeClass='active' className='nav_item' to="#responsibilities" spy={true} smooth={true} duration={800} >Responsibilities</HashLink></li>
        <li className='li_item'><HashLink onClick={()=>{setvis(false) ; checked()}} activeClass='active' className='nav_item' to="#faqs" spy={true} smooth={true} duration={800} >FAQs</HashLink></li>
        <li className='li_item'><HashLink onClick={()=>{setvis(false) ; checked()}} activeClass='active' className='nav_item' to="#footer" spy={true} smooth={true} duration={800} >Contact Us</HashLink></li>
        <li className='li_item'> <a href="data/Campus.pdf" className="nav_item" download="Campus.pdf">Brochure</a>
        </li> 
        <li className='li_item'><a activeClass='active' className='nav_item' href="https://forms.gle/2FZQ9VwTCpaiQZET8">Apply Now</a></li> 

        
        
        
      </div>
      <div className="registration">
 

     
          {/* <li><a className="log_in" href="#">LOG IN</a></li>

        <li><a className="register" href="#">REGISTER</a></li> */}
          <li className="resp_nav">
            <input
              id="toggle"
              ref={target}
              type="checkbox"
              onClick={() => {
                setvis(!vis);
              }}
            ></input>

            <label for="toggle" class="hamburger">
              <div class="top-bun"></div>
              <div class="meat"></div>
              <div class="bottom-bun"></div>
            </label>
          </li>
        </div>
      </div>
    </>
  );

}
