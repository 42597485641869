import React from "react";
import "../css/Theme.css";
import { motion } from "framer-motion";
import styles from "../css/theme.module.css";
import { useMediaQuery } from "react-responsive";

const AnimatedText = (text) => {
  const letters = text.text.split("");
  const center_length = letters.length / 2;
  const speedIndex = [];
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });

  for (const [index, letter] of letters.entries()) {
    speedIndex.push(
      index < center_length
        ? ((center_length - index + 1) * 1) / center_length
        : letters.length % 2 === 0
        ? speedIndex[letters.length - index]
        : speedIndex[letters.length - index - 1]
    );
  }
  return (
    <div className={``}>
      <div
        className={
          text.classText === ""
            ? `text-3xl text-nowrap ${styles.title}`
            : text.classText
        }
      >
        {letters.map((letter, index) => (
          <motion.span
            initial={{
              fontSize: isSmallScreen ? "6rem" : "10rem",
              top: "6rem",
            }}
            whileInView={{
              fontSize: isSmallScreen ? "2.5rem" : "5rem",
              top: "0",
            }}
            transition={{
              duration: speedIndex[index],
              ease: "linear",
              type: "spring",
            }}
            exit={{ top: "0" }}
            className="relative"
          >
            <motion.span
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                duration: speedIndex[index],
              }}
              animate={{
                opacity: [0, 0, 0, 1],
              }}
            >
              {letter}
            </motion.span>
          </motion.span>
        ))}
      </div>
    </div>
  );
};

function Theme() {
  const text1 = "Fueling ambition";
  const text2 = "forging empires";
  return (
    <div className="w-screen h-[28rem] py-40 gap-8 flex flex-col justify-center">
      <AnimatedText
        text={"OUR THEME"}
        classText={`font-3xl text-white text-center capitalize font-semibold text-nowrap`}
      />
      <AnimatedText text={text1} classText="" />
      <AnimatedText text={text2} classText="" />
    </div>
  );
}

export default Theme;
