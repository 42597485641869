import React from 'react';
import { motion } from 'framer-motion';
import PhotoFrame from './PhotoFrame';
import styles from '../../css/Gallery_Layout.module.css';
import Navbar from '../../pages/Navbar';
import galleryImages from '../../data/galleryImages';
import { useEffect } from 'react';
export default function Gallery_Layout() {
    const heading1 = "GLIMPSES OF PREVIOUS";
    const heading2 = "E-SUMMIT";

    const letterAnimation = {
        hidden: { opacity: 0, y: 50 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.05,
            },
        }),
    };

    const letterAnimationWithDelay = {
        hidden: { opacity: 0, y: 50 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: heading1.length * 0.05 + i * 0.05, // Add delay for E-SUMMIT
            },
        }),
    };
    useEffect(() => {
        const handleBeforeUnload = () => {
          sessionStorage.setItem("cameFromGalleryPage", "true");
        };
    
        window.addEventListener("beforeunload", handleBeforeUnload);
    
        return () => {
          window.removeEventListener("beforeunload", handleBeforeUnload);
          sessionStorage.setItem("cameFromGalleryPage", "true");
        };
      }, []);

    return (
        <>
            <Navbar />
            <div className={styles.heading}>
               <h1 style={{ fontWeight: 'normal', color: 'orange' }}>
                    {heading1.split("").map((char, index) => (
                        <motion.span
                            key={index}
                            custom={index}
                            initial="hidden"
                            animate="visible"
                            variants={letterAnimation}
                        >
                            {char}
                        </motion.span>
                    ))}
                </h1>
                
                    {heading2.split("").map((char, index) => (
                        <motion.span
                            key={index}
                            custom={index}
                            initial="hidden"
                            animate="visible"
                            variants={letterAnimationWithDelay}
                        >
                            {char}
                        </motion.span>
                    ))}
             
            </div>
            <div className={styles.gridLayout}>
                {galleryImages.map((imagePath, index) => (
                    <motion.div
                        key={index}
                        className={styles.photoFrameWrapper}
                        whileHover={{ scale: 1.1 }}
                        transition={{ duration: 0.3 }}
                    >
                        <PhotoFrame imagePath={imagePath} />
                    </motion.div>
                ))}
            </div>
        </>
    );
}
