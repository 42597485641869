import React from 'react'
import style from "../css/winner.module.css"
import Navbar from "./Navbar";
import img7 from "../images/winners/Case-1.jpg";
import img8 from "../images/winners/Casechronicle-1.jpg"
import img9 from "../images/winners/Case-2.jpg"
import img10 from "../images/winners/Casech-2.jpg";
import img11 from "../images/winners/Casechro-2.jpg";
import img12 from "../images/winners/Casechronicle-2.jpg";
import img13 from "../images/winners/Case-3.jpg";
import img14 from "../images/winners/Casech-3.jpg";
import img15 from "../images/winners/Casechr-3.jpg";
import img16 from "../images/winners/Casechro-3.jpg";
import img17 from "../images/winners/Casechronicle-3.jpg";
import img18 from "../images/winners/Entrivia-2.jpg";
import img19 from "../images/winners/Entrivia-2nd.jpg";
import img20 from "../images/winners/Trading-2.jpg";

export default function Winners() {
    return (
        <>
         <Navbar />
            <div id="winners">
           
                <div className={style.body}>
                    <div className={style.text1}>Case Chronicles</div>
                    <div className={style.Grid}>
                        <div className={style.Gridchild}>
                            <img src={img7} className={style.image} alt="winner" />
                            <div className={style.content}><h1>Chaitanya Agrawal</h1></div>
                        </div>
                        <div className={style.Gridchild}>
                            <img src={img8} className={style.image} alt="winner" />
                            <div className={style.content}><h1>Harsh Goel</h1></div>
                        </div>
                        <div className={style.Gridchild}>
                            <img src={img9} className={style.image} alt="winner" />
                            <div className={style.content}><h1>Om Kumar</h1></div>
                        </div>
                    {/* </div>
                    <div className={style.Grid}> */}
                        <div className={style.Gridchild}>
                            <img src={img10} className={style.image} alt="winner" />
                            <div className={style.content}><h1>Om Kumar Rakshit</h1></div>
                        </div>
                        <div className={style.Gridchild}>
                            <img src={img11} className={style.image} alt="winner" />
                            <div className={style.content}><h1>Saksham Gupta</h1></div>
                        </div>
                        <div className={style.Gridchild}>
                            <img src={img12} className={style.image} alt="winner" />
                            <div className={style.content}><h1>Shisir Hardia</h1></div>
                        </div>
                    {/* </div>
                    <div className={style.Grid}> */}
                        <div className={style.Gridchild}>
                            <img src={img13} className={style.image} alt="winner" />
                            <div className={style.content}><h1>Anjali Wason</h1></div>
                        </div>
                        <div className={style.Gridchild}>
                            <img src={img14} className={style.image} alt="winner" />
                            <div className={style.content}><h1>Maneeshima Ray</h1></div>
                        </div>
                        <div className={style.Gridchild}>
                            <img src={img15} className={style.image} alt="winner" />
                            <div className={style.content}><h1>Ayush Shukla</h1></div>
                        </div>
                    {/* </div>
                    <div className={style.Grid}> */}
                        <div className={style.Gridchild}>
                            <img src={img16} className={style.image} alt="winner" />
                            <div className={style.content}><h1>Prerna Sahu</h1></div>
                        </div>
                        <div className={style.Gridchild}>
                            <img src={img17} className={style.image} alt="winner" />
                            <div className={style.content}><h1>Geetika Tirumalaseety</h1></div>
                        </div>
                        <div className={style.Gridchild}>
                        </div>
                    </div>
                    <div className={style.text1}>Entrivia</div>
                    <div className={style.Grid}>
                        <div className={style.Gridchild}>
                            <img src={img18} className={style.image} alt="winner" />
                            <div className={style.content}><h1>Abhishek Kashyap</h1></div>
                        </div>
                        <div className={style.Gridchild}>
                            <img src={img19} className={style.image} alt="winner" />
                            <div className={style.content}><h1>Shreya</h1></div>
                        </div>
                        <div className={style.Gridchild}>
                        </div>
                    </div>
                    <div className={style.text1}>Trading Vault</div>
                    <div className={style.Grid}>
                        <div className={style.Gridchild}>
                            <img src={img20} className={style.image} alt="winner" />
                            <div className={style.content}><h1>Sourav Singh</h1></div>
                        </div>
                        <div className={style.Gridchild}>
                        </div>
                        <div className={style.Gridchild}>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}
