import React, { useState } from "react";
import Navbar from "./NavbarCA";
import { useContext, useEffect } from "react";
import { LoginContext } from "../helpers/context/Context";
import style from "../css/homepage.module.css";
import styles from "../css/campusambassador.module.css";
import img1 from "../images/capage/p1.jpg";
import img2 from "../images/capage/p2.jpg";
import img3 from "../images/capage/p3.jpg";
import img4 from "../images/capage/p4.jpg";
import img5 from "../images/capage/p5.jpg";
import img6 from "../images/capage/p3.jpg";
import img7 from "../images/capage/p1.jpg";
import img8 from "../images/capage/p2.jpg";
import {
  eSummitDescription,
  caWhyApply,
  caResponsibilities,
  faqs,
} from "../data/constants";

import { Button, ButtonGroup } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";

function PastSpeakers(props) {
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const { loading, setLoading } = useContext(LoginContext);
  useEffect(() => {
    setLoading(true);
    const Load = async () => {
      await new Promise((r) => setTimeout(r, 4000));

      setLoading(false);
    };

    Load();
  }, []);
  return (
    <>
      {loading && 
       <div className="w-[100vw] h-[100vh] z-1000 fixed bg-[#000000] flex items-center justify-center">
         <img className="bg-cover" src="./preloader/loader.gif" width={"80%"} height={"80%"}/>
       </div>
       
      }
      {!loading && (
        <>
          <Navbar />
          <div
            className={` relative flex gap-[1rem] flex-col w-[100%]  items-center text-white`}
            style={{
              background: "rgb(15,15,15)",
            }}
          >
            <img
              src="/data/cabg.svg"
              alt="ca"
              className=" opacity-50 brightness-150 absolute w-[100%] h-[100%]  object-cover object-top  animate-pulse"
            />

            <div
              className={` w-[100%] gap-[15px] flex flex-col md:flex-row  justify-around items-center mt-[10rem] p-10`}
            >
              <div
                className={`${styles.hoverparent} relative z-50 flex w-[75%] md:w-[600px] gap-7 items-center justify-center text-[1.5rem]   flex-col`}
              >
                <div className="relative flex w-[95%] md:w-[600px] gap-[20px] items-center justify-center text-[1.5rem] px-[15px] md:py-[200px] py-[50px] border-[2px] border-white  flex-col backdrop-blur-[100px]">
                  <div
                    className={` text-[4.5rem] max-md:text-[2rem] text-center text-white ${styles.jura}`}
                  >
                    ESummit&#39; 2024
                  </div>
                  <div
                    className={` text-[2rem] max-md:text-[1.7rem] text-center text-white ${styles.playtime}`}
                  >
                    Campus Ambassador Program
                  </div>
                  <a href="https://forms.gle/2FZQ9VwTCpaiQZET8">
                    <div className=" hover:scale-[1.1] transition-all duration-300 text-[1.5rem] text-white  bg-gradient-to-br from-[#d07d2c]  to-[#b9372b] p-3 rounded-md">
                      Apply Now
                    </div>
                  </a>
                </div>
                <div
                  className={`${styles.rotatingchild} absolute -z-10  w-[100%] md:w-[600px] h-[100%] border-[2px] border-white -rotate-[10deg] `}
                ></div>
              </div>
              <div className=" relative bg-gradient-to-br from-[#d07e2c36]  to-[#b9372b3c]  w-[50%] max-lg:absolute max-md:w-[95%] z-40">
                <video
                  loop
                  autoPlay
                  className=" w-[100%]  h-[50vh] min-h-full min-w-full relative z-40"
                  allowFullScreen
                  controls
                >
                  <source src="/glimpses.mp4" type="video/mp4" />
                </video>
              </div>
            </div>

            <div
              id="aboutus"
              className="  w-[100%]  flex flex-col justify-center items-center   "
            >
              <div className=" relative  flex flex-col p-10 rounded-[20px]">
                <div
                  className={` text-[2.5rem]  text-center text-white pb-[1rem] hover:scale-125  ${styles.jura} `}
                >
                  About{" "}
                  <span className=" text-[#b9372b] text-[4rem] brightness-[150%] font-semibold animate-pulse ">
                    ESummit
                  </span>
                </div>
                <div className="text-[1.3rem] text-justify text-white px-[1rem] sm:px-[1rem] lg:px-[3rem] xl:px-[6.5rem] md:px-[1rem]">
                  <div
                    dangerouslySetInnerHTML={{ __html: eSummitDescription }}
                  />
                </div>
              </div>
              <div className={`${styles.carouselContainer} relative  mb-8 `}>
                <div className={styles.carouselTrack}>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img1}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgdn}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img2}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgup}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img3}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgdn}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img4}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgup}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img5}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgdn}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img6}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgup}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img7}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgdn}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img8}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgup}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img1}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgdn}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img2}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgup}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img3}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgdn}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img4}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgup}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img5}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgdn}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img6}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgup}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img7}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgdn}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img8}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgup}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img1}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgdn}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img2}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgup}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img3}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgdn}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img4}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgup}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img5}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgdn}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img6}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgup}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img7}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgdn}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img8}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgup}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img1}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgdn}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img2}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgup}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img3}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgdn}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img4}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgup}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img5}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgdn}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img6}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgup}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img7}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgdn}`}
                    />
                  </div>
                  <div className={styles.carouselSlide}>
                    <img
                      src={img8}
                      alt="img1"
                      className={`${styles.imag} ${styles.imgup}`}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Carousel */}

            <div
              id="whyca"
              className="w-[100%]  flex flex-col  justify-start  items-center  gap-3 overflow-hidden"
            >
              <div
                className={`text-[2.5rem]  text-white p-10  ${styles.jura} hover:scale-125 `}
              >
                Why{" "}
                <span className=" text-[#b9372b] text-[4rem] brightness-[150%] font-semibold animate-pulse">
                  CA?
                </span>
              </div>
              <div className={styles.cardContainer}>
                <div className={styles.card}>
                  <div
                    className={`${styles.cardside} ${styles.front} relative`}
                  >
                    Recommendation
                    <img
                      src="/data/ecell.png"
                      alt=""
                      className="absolute w-[70px] bottom-[20px] brightness-0 invert "
                    />
                  </div>
                  <div className={`${styles.cardside} ${styles.back}`}>
                    Letter of Recommendation from Esummit IIT Indore to Top 50
                    CAs.
                  </div>
                </div>
                <div className={styles.card}>
                  <div className={`${styles.cardside} ${styles.front}`}>
                    Connecting Students
                    <img
                      src="/data/ecell.png"
                      alt=""
                      className="absolute w-[70px] bottom-[20px] brightness-0 invert "
                    />
                  </div>
                  <div className={`${styles.cardside} ${styles.back}`}>
                    Stand a chance to collaborate with Esummit IIT Indore to
                    organize workshops and tech and entrepreneurship
                    competitions at your institution
                  </div>
                </div>
                <div className={styles.card}>
                  <div className={`${styles.cardside} ${styles.front}`}>
                    Upskilling
                    <img
                      src="/data/ecell.png"
                      alt=""
                      className="absolute w-[70px] bottom-[20px] brightness-0 invert "
                    />
                  </div>
                  <div className={`${styles.cardside} ${styles.back}`}>
                    The CA Program helps us realize this vision. Here, the CAs
                    build a network of students from 1000+ colleges and learn
                    about social media, marketing, event planning and execution.
                  </div>
                </div>

                <div className={styles.card}>
                  <div className={`${styles.cardside} ${styles.front}`}>
                    Driving Success
                    <img
                      src="/data/ecell.png"
                      alt=""
                      className="absolute w-[70px] bottom-[20px] brightness-0 invert "
                    />
                  </div>
                  <div className={`${styles.cardside} ${styles.back}`}>
                    They are rewarded for fulfilling their responsibilities and
                    motivated to continually strive to improve their personal
                    skills. The role of the CAs is more critical than ever
                    before, and this is an excellent opportunity for us all to
                    work together and make the 11th edition of Fluxus a grand
                    succes
                  </div>
                </div>
              </div>
            </div>

            <div
              id="incentives"
              className={`text-[2.5rem]  text-white p-10 relative hover:scale-125  ${styles.jura} `}
            >
              Why Should You{" "}
              <span className=" text-[#b9372b] text-[4rem] brightness-[150%] font-semibold animate-pulse">
                Apply
              </span>
              ?
            </div>

            <div
              className={`relative  w-[98%]  items-center overflow-y-scroll flex flex-col gap-[10px] scroll-m-0 ${styles.scroll}`}
            >
              {caWhyApply.map((item, index) => (
                <div
                  className={` ${styles.streak} text-[1.3rem] min-h-[9rem] w-[90%] bg-[#58101085] rounded-md text-white p-[10px] flex justify-center items-center text-justify`}
                >
                  {item}
                </div>
              ))}
            </div>

            <div
              id="responsibilities"
              className={`text-[2.5rem]  text-white p-10 hover:scale-125   ${styles.jura} `}
            >
              Your{" "}
              <span className=" text-[#b9372b] text-[3rem] sm:text-[4rem] brightness-[150%] font-semibold animate-pulse">
                Responsibilities
              </span>
            </div>

            {caResponsibilities.map(
              (item, index) =>
                (index + 1) % 2 !== 0 && (
                  <div className="relative w-[100%] flex flex-col gap-[30px] md:gap-0 items-center justify-end md:flex-row md:mt-[20px] md:mb-[40px]">
                    <div class=" md:border-2 md:border-dashed md:absolute md:w-[90vw] top-0  right-0"></div>
                    <div className="md:w-[45%] w-[90%]  relative flex flex-col md:px-[100px] gap-[30px] md:py-[50px] items-center">
                      <div className="md:absolute left-[-51px] top-[-51px]  border-[6px] rounded-[50%] border-white p-[16px] bg-[rgb(15,15,15)]">
                        <img src={`${item.icon}` || ""} alt="" width={70} />
                      </div>

                      <div className="text-[1.8rem] font-semibold ">
                        {item.title}
                      </div>
                      <ul className=" list-disc text-[1.3rem] px-4">
                        <li>{item.description}</li>
                      </ul>
                    </div>

                    {caResponsibilities[index + 1] && (
                      <div className="md:w-[45%] w-[90%]  relative flex flex-col md:px-[100px] gap-[30px] md:py-[50px] items-center">
                        <div className="md:absolute left-[-51px] top-[-51px]  border-[6px] rounded-[50%] border-white p-[16px] bg-[rgb(15,15,15)]">
                          <img
                            src={`${caResponsibilities[index + 1].icon}` || ""}
                            alt=""
                            width={70}
                          />
                        </div>

                        <div className="text-[1.8rem] font-semibold ">
                          {caResponsibilities[index + 1].title}
                        </div>
                        <ul className=" list-disc text-[1.3rem] px-4">
                          <li>{caResponsibilities[index + 1].description}</li>
                        </ul>
                      </div>
                    )}
                  </div>
                )
            )}

            <div
              className={`text-[3rem] hover:scale-125   text-white p-10  ${styles.jura} `}
            >
              <div>FAQs</div>
            </div>

            <Accordion className=" relative my-7 md:w-[85%] w-[95%] flex flex-col gap-[40px]">
              {faqs.map((faq, index) => (
                <AccordionItem className=" flex flex-col gap-[15px] border-b-[2px] border-[#ffffff79] pb-[20px]">
                  <h2>
                    <AccordionButton>
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        className=" text-[1.5rem] font-semibold"
                      >
                        {faq.question}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} className=" text-[1.2rem]">
                    {faq.answer}
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>

            <div id="footer"></div>
          </div>
        </>
      )}
    </>
  );
}

export default PastSpeakers;
