import React from "react";
import "../css/about.css";
import { motion } from "framer-motion";
import styles from "../css/about.module.css";

export default function About() {
  const text =
    "E-Summit is the flagship event organised by the E-Cell, IIT Indore annually. It is one of the most awaited events hosted by the institute that witnesses massive participation nationwide. Through this event, we aim to ignite the entrepreneur inside students by bringing them together to compete in various competitions exploring seasoned and celebrated entrepreneurs who enlighten our audience with their jaw-dropping journeys. E-Summit aims to help students develop an entrepreneurial mindset and we aim to go beyond talking just about mere businesses. Entrepreneurship is a spirit and that’s what we celebrate here.".split(
      " "
    );
  return (
    <>
      <motion.div className="w-screen grid content-center justify-center ">
        <motion.div
          initial={{ opacity: 0.2, transform: `scale(0.1)` }}
          whileInView={{ opacity: 1, transform: `scale(1)` }}
          transition={{
            duration: 1,
            ease: "linear",
            type: "spring",
          }}
          className={`p-16 my-8 rounded-3xl w-[100vw] gap-9 ${styles.aboutDiv}`}
        >
          {/* <h1 className={`mb-12 ${styles.title} princess-sofia-regular`}>
            About Us
          </h1> */}

          <div className=" relative text-white text-center flex justify-center">
            <img
              className={`absolute xl:top-[-300px] lg:top-[-400px] md:top-[-420px] top-[-400px] sm:top-[-380px] -z-10  rounded-3xl xl:h-[1000px] xl:w-[1600px] lg:w-[1400px] lg:h-[1200px] md:h-[1150px] sm:h-[1100px] h-[1200px] sm:scale-[110%] scale-125 ${styles.scroll}`}
              src="/data/content/scroll.png"
            ></img>
            <div className=" z-10 2xl:py-[3rem] xl:py-[1rem] lg:py-[0rem]  lg:px-[8rem] px-[1.2rem] sm:px-[4rem] md:px-[3rem] xl:text-[31px] lg:text-[28px] md:text-[25px] sm:text-[20px] xl:w-[1400px] lg:w-[1000px] md:w-[700px] text-[#ffdab6]  opacity-90 dancingscript ">
              {text.map((el, i) => (
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 1,
                    delay: i / 20,
                  }}
                  key={i}
                >
                  {el}{" "}
                </motion.span>
              ))}
            </div>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
}
