import React, { useContext, useEffect, useState } from "react";
import Navbar from "./Navbar";
import Events from "./Events";
import Speaker from "./Speakers";
import speakerData from "../data/speakers.json";
import styles from "../css/parallax.module.css";
import { LoginContext } from "../helpers/context/Context";
import Footer from "./Footer";
import About from "./About";
import EventCoverageAndFootfall from "./EventCoverageAndFootfall";
import Theme from "./Theme";
import TextReveal from "../components/reveal.tsx";

export default function Homepage() {
  const { loading, setLoading } = useContext(LoginContext);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const hasVisitedBefore = sessionStorage.getItem("hasVisitedHomepage");
    const navEntries = performance.getEntriesByType("navigation");
    const isPageReload =
      navEntries.length > 0 && navEntries[0].type === "reload";

    // Check if the user is coming back from any of the specified pages
    const cameFromSpecificPage = [
      "cameFromTeamPage",
      "cameFromPartnersPage",
      "cameFromFaqPage",
      "cameFromGalleryPage",
      "cameFromPastSpeakersPage",
    ].some((page) => sessionStorage.getItem(page) === "true");

    // console.log(isComingBackFromTeam)
    console.log(sessionStorage);

    if (!hasVisitedBefore || (isPageReload && !cameFromSpecificPage)) {
      setShowLoader(true);
      setLoading(true);
      const load = async () => {
        await new Promise((r) => setTimeout(r, 4000));
        setLoading(false);
        setShowLoader(false);
        sessionStorage.setItem("hasVisitedHomepage", "true");
      };
      load();
    } else {
      setShowLoader(false);
      setLoading(false);
    }
  }, [setLoading]);
  // Clean up the sessionStorage flags
  [
    "cameFromTeamPage",
    "cameFromPartnersPage",
    "cameFromFaqPage",
    "cameFromGalleryPage",
    "cameFromPastSpeakersPage",
  ].forEach((page) => sessionStorage.removeItem(page));
  console.log(sessionStorage);

  return (
    <>
      {loading && showLoader && (
        <div className={styles.loader}>
          <img
            className="bg-cover"
            src="./preloader/loader.gif"
            width={"80%"}
            height={"80%"}
          />
        </div>
      )}
      {(!loading || !showLoader) && (
        <>
          <Navbar />
          <div className={styles.banner}>
            <header>
              <img
                className={`${styles.background} `}
                src="./data/parallax/masterbg.png"
                alt="logo"
              />

              <img
                className={styles.middle1}
                src="./data/parallax/denhd.png"
                alt="logo"
              />
              <img
                className={styles.middle2}
                src="./data/parallax/castlehd.png"
                alt="logo"
              />
              <img
                className={styles.middle3}
                src="./data/parallax/buildinghd.png"
                alt="logo"
              />
              <img
                className={styles.middle4}
                src="./data/parallax/warriorhd.png"
                alt="logo"
              />
              <img
                className={styles.foreground}
                src="./data/parallax/grass.png"
                alt="logo"
              />
              <div className={styles.foreground2}>
                
              </div>

              <div className={styles.logo}>
                <img
                  className={styles.bulb}
                  src="./data/useBulb.GIF"
                  alt="logo"
                />
                <img
                  className={styles.title}
                  src="./data/esummitheader.png"
                  alt="logo"
                />
              </div>
            </header>
           
            <div className={styles.content}>
              {/* about */}
              <div className={` w-[100vw]  bg-black `}>
                {/* background */}
                <div
                  className={` absolute w-[100%] h-[100%] brightness-50 opacity-40 ${styles.aboutusbg}`}
                ></div>
                {/* background */}

                {/* cloud */}
                <div className="  min-h-[250px] min-[2000px]:min-h-[400px]  relative 2xl:translate-y-[-20%]">
                  <img
                    className={`${styles.cloud2} cloud1 top-[-70%] h-auto min-w-[800px] md:min-w-[1000px] sm:left-[-30%] left-[-40%] md:left-[-20%] lg:left-[-20%] xl:min-w-[1150px] 2xl:min-w-[1200px]  min-[2000px]:min-w-[2000px] min-[2000px]:left-[-30%] `}
                    src="./data/parallax/cloud.png"
                    alt="logo"
                  />
                  <img
                    className={`${styles.cloud2} cloud2 top-[-70%] h-auto min-w-[800px] md:min-w-[1000px] right-[-40%] sm:right-[-30%] md:right-[-20%] lg:right-[-20%]  xl:min-w-[1150px] 2xl:min-w-[1200px] min-[2000px]:min-w-[2000px] min-[2000px]:right-[-30%]  z-10`}
                    src="./data/parallax/cloud.png"
                    alt="logo"
                  />
                  <img
                    className={`${styles.cloud2} cloud3 top-[-70%] h-auto min-w-[800px] md:min-w-[1000px]  md:left-[20%] lg:left-[15%] xl:min-w-[1150px]  sm:flex hidden 2xl:min-w-[1200px] min-[2000px]:min-w-[2000px] min-[2000px]:left-[10%] z-20 `}
                    src="./data/parallax/cloud.png"
                    alt="logo"
                  />

                  <div className=" absolute top-[] 2xl:top-[50%] z-40 text-white w-[100vw] text-center xl:text-[10rem] lg:text-[7.8rem] md:text-[5.2rem] sm:text-[3.8rem] text-[1.5rem] orbitron uppercase font-extrabold opacity-70 motion">
                    About Us
                  </div>
                </div>
                <div className=" w-[100vw]  h-[150px] "></div>
                {/* cloud */}

                {/* content */}

                <About />
                <EventCoverageAndFootfall />

                {/* content */}

                {/* paddingbottom */}
                <div className=" w-[100vw] h-[150px] "></div>
                {/* paddingbottom */}
              </div>
              {/* about */}
              
            </div>
            <div className={styles.content}>
              {/* events */}
              <div className={` w-[100vw]  bg-black `}>
                {/* background */}
                <div
                  className={` absolute w-[100%] h-[100%] brightness-50 opacity-40 ${styles.eventsbg}`}
                ></div>
                {/* background */}

                {/* cloud */}
                <div className="  min-h-[250px] min-[2000px]:min-h-[400px]  relative 2xl:translate-y-[-20%]">
                  <img
                    className={`${styles.cloud2} cloud1 md:min-w-[1000px] top-[-70%] h-auto min-w-[800px] sm:left-[-30%] left-[-40%] md:left-[-20%] lg:left-[-20%] xl:min-w-[1150px] 2xl:min-w-[1200px]  min-[2000px]:min-w-[2000px] min-[2000px]:left-[-30%] `}
                    src="./data/parallax/cloud.png"
                    alt="logo"
                  />
                  <img
                    className={`${styles.cloud2} cloud2 md:min-w-[1000px] top-[-70%] h-auto min-w-[800px] right-[-40%] sm:right-[-30%] md:right-[-20%] lg:right-[-20%]  xl:min-w-[1150px] 2xl:min-w-[1200px] min-[2000px]:min-w-[2000px] min-[2000px]:right-[-30%]  z-10`}
                    src="./data/parallax/cloud.png"
                    alt="logo"
                  />
                  <img
                    className={`${styles.cloud2} cloud3 md:min-w-[1000px] top-[-70%] h-auto min-w-[800px]  md:left-[20%] lg:left-[15%] xl:min-w-[1150px]  sm:flex hidden 2xl:min-w-[1200px] min-[2000px]:min-w-[2000px] min-[2000px]:left-[10%] z-20 `}
                    src="./data/parallax/cloud.png"
                    alt="logo"
                  />

                  <div className=" absolute top-[] 2xl:top-[50%] z-40 text-white w-[100vw] text-center xl:text-[7rem] lg:text-[6rem] md:text-[5rem] sm:text-[3rem] text-[1rem] orbitron uppercase font-extrabold  opacity-70 motion">
                  Events & Competitions
                  </div>
                </div>
                <div className=" w-[100vw]  h-[10px]  "></div>
                {/* cloud */}

                {/* content */}

                <Events />

                {/* content */}

                {/* paddingbottom */}
                <div className=" w-[100vw] h-[150px] "></div>
                {/* paddingbottom */}
              </div>
              {/* events */}
              
            </div>
            <div className={styles.content}>
              {/* speakers */}
              <div className={` w-[100vw]  bg-black `}>
                {/* background */}
                <div
                  className={` absolute w-[100%] h-[100%] brightness-75 opacity-40 ${styles.speakersbg}`}
                ></div>
                {/* background */}

                {/* cloud */}
                <div className="  min-h-[250px] min-[2000px]:min-h-[400px]  relative 2xl:translate-y-[-20%]">
                  <img
                    className={`${styles.cloud2} cloud1 md:min-w-[1000px] top-[-70%] h-auto min-w-[800px] sm:left-[-30%] left-[-40%] md:left-[-20%] lg:left-[-20%] xl:min-w-[1150px] 2xl:min-w-[1200px]  min-[2000px]:min-w-[2000px] min-[2000px]:left-[-30%] `}
                    src="./data/parallax/cloud.png"
                    alt="logo"
                  />
                  <img
                    className={`${styles.cloud2} cloud2 md:min-w-[1000px] top-[-70%] h-auto min-w-[800px] right-[-40%] sm:right-[-30%] md:right-[-20%] lg:right-[-20%]  xl:min-w-[1150px] 2xl:min-w-[1200px] min-[2000px]:min-w-[2000px] min-[2000px]:right-[-30%]  z-10`}
                    src="./data/parallax/cloud.png"
                    alt="logo"
                  />
                  <img
                    className={`${styles.cloud2} cloud3 md:min-w-[1000px] top-[-70%] h-auto min-w-[800px]  md:left-[20%] lg:left-[15%] xl:min-w-[1150px]  sm:flex hidden 2xl:min-w-[1200px] min-[2000px]:min-w-[2000px] min-[2000px]:left-[10%] z-20 `}
                    src="./data/parallax/cloud.png"
                    alt="logo"
                  />

                  <div className=" absolute top-[] 2xl:top-[50%] z-40 text-white w-[100vw] text-center xl:text-[8rem] lg:text-[7.8rem] md:text-[5.2rem] sm:text-[3.8rem] text-[1.5rem] orbitron uppercase font-extrabold opacity-70 motion">
                  Visionary Vault
                  </div>
                </div>

                {/* <div className=" w-[100vw]  h-[50px] "></div> */}


                {/* cloud */}

                {/* speakers */}

                <Speaker speakers={speakerData.visionaryVault} />
                <div>
                <div className="2xl:top-[50%] z-40 text-white w-[100vw] text-center xl:text-[8rem] lg:text-[7.8rem] md:text-[5.2rem] sm:text-[3.8rem] text-[1.5rem] orbitron uppercase font-extrabold opacity-70 motion">
                  Innovators Unplugged
                  </div>
                  </div>

                <Speaker speakers={speakerData.innovatorsUnplugged} />

                {/* speakers */}

                {/* paddingbottom */}

                <div className=" w-[100vw] h-[110px] "></div>
     

                {/* paddingbottom */}
              </div>
              {/* speakers */}
              
            </div>
            <Footer />
          </div>
        </>
      )}
    </>
  );
}