import React, { useState ,useEffect} from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import "../css/faq.css";
import Navbar from "./Navbar";

export default function FrequentlyAskedQuestions(props) {
   const [expandedItemIndex, setExpandedItemIndex] = useState(-1);

   const toggleExpand = (index) => {
      if (expandedItemIndex === index) {
         // Clicked on the already expanded item, so close it
         setExpandedItemIndex(-1);
      } else {
         // Clicked on a new item, close the previously expanded item and open the clicked one
         setExpandedItemIndex(index);
      }
   };
   useEffect(() => {
      const handleBeforeUnload = () => {
        sessionStorage.setItem("cameFromFaqPage", "true");
      };
  
      window.addEventListener("beforeunload", handleBeforeUnload);
  
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        sessionStorage.setItem("cameFromFaqPage", "true");
      };
    }, []);
    
      const [isExpanded, setIsExpanded] = useState(false);
    
      const toggleExpand2 = () => {
        setIsExpanded(!isExpanded);
      };
   return (
      <>
         <Navbar />
         <div className="backgrounds" style={{}}>
            <div className="pt-24 text-center justify-center items-center div h-100vh">
               <div className="heading"> <span className="title">Frequently Asked Questions</span></div>
               <div className="div  text-white div w-3/5">
               <div className="text-left">
               
      <div className="border-b-2 py-6 data">
        <div className="flex items-center">
          <div className="text-white w-4/5">
          Where can I purchase the E-Summit merchandise?
          </div>
          <span className="icon">
            <div className="text-right">
              <button onClick={toggleExpand2}>
                {isExpanded ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
              </button>
            </div>
          </span>
        </div>
        
        {isExpanded && (
          <div className="answer text-slate-300 text-xl">
           You can purchase the E-SUMMIT merchandise, customised specially for the theme of E-Summit 2024 by clicking <a href='https://forms.gle/kmMw3E57YTPpFGqg8' text-green>HERE</a> 
          </div>
        )}
        </div>
      </div>
    </div>
               <div className="div py-4 text-white div w-3/5">
                  {props.question.map((val, ind) => (
                     <div className="text-left" key={ind}>
                        <div className="border-b-2 py-6 data">
                           <div className="flex items-center">
                              <div className="w-4/5">
                                 {val.data}
                              </div>
                              <span className="icon">
                                 <div className="text-right">
                                    <button onClick={() => toggleExpand(ind)}>
                                       {expandedItemIndex === ind ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                                    </button>
                                 </div>
                              </span>
                           </div>
                        
                           {expandedItemIndex === ind && (
                              <div className="answer text-slate-300 text-xl">
                                 {val.answer}
                              </div>
                           )}
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </div>
      </>
   );
}
